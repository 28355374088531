import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { AboutMeService } from '../../aboutme.service';
import { ComponentFormData } from '../component-form-data';
import { RemainingSpaceModule } from '../../components/remaining-space/remaining-space.module';

export interface ProfileFormData extends ComponentFormData {
    name: string;
    jobTitle: string;
    email: string;
    phone: string;
    bio: string;
    imageFileKey: string;
}

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSnackBarModule,
        TranslocoModule,
        MatIconModule,
        RemainingSpaceModule,
    ],
})
export class ProfileFormComponent implements OnInit {
    @Input() set data(value: ProfileFormData) {
        this._data = value;
        if (this.form) {
            this.form.patchValue(value);
        }
    }
    get data(): ProfileFormData {
        return this._data;
    }
    private _data: ProfileFormData = {
        name: '',
        jobTitle: '',
        email: '',
        phone: '',
        bio: '',
        imageFileKey: '',
    };

    @Output() dataChange = new EventEmitter<ProfileFormData>();
    @Output() cancel = new EventEmitter<void>();

    form!: FormGroup;

    imageBaseUrl = `${environment.apiUrl}/files/`;

    constructor(
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService,
        private aboutMeService: AboutMeService,
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            name: [this.data.name, [Validators.required]],
            jobTitle: [this.data.jobTitle],
            email: [this.data.email, [Validators.email]],
            phone: [this.data.phone, [Validators.minLength(4)]],
            bio: [this.data.bio, [Validators.required]],
            imageFileKey: [this.data.imageFileKey],
        });
    }

    handleFiles(files: FileList) {
        const validImageTypes = [
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/webp',
        ];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validImageTypes.includes(file.type)) {
                this.snackBar.open(
                    this.translocoService.translate(
                        'dashboard.aboutme-editor.component-forms.profile-form.invalid-file-type',
                    ),
                    'Close',
                    { duration: 3000, panelClass: ['error-snackbar'] },
                );
                continue;
            }

            if (file.size > 1048576) {
                this.snackBar.open(
                    this.translocoService.translate(
                        'dashboard.aboutme-editor.component-forms.profile-form.file-size-exceeds-1mb',
                    ),
                    'Close',
                    { duration: 3000, panelClass: ['error-snackbar'] },
                );
                continue;
            }

            this.aboutMeService.uploadFile(file).subscribe({
                next: (response) => {
                    this.form.patchValue({ imageFileKey: response.key });
                    this.aboutMeService.notifyRemainingSpaceUpdate();
                },
                error: (error) => {
                    console.error('Error uploading file:', error);
                },
            });
        }
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files) {
            this.handleFiles(input.files);
        }
    }

    removeImage() {
        this.form.patchValue({ imageFileKey: null });
    }

    onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer?.files;
        if (files) {
            this.handleFiles(files);
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.dataChange.emit(this.form.value);
        } else {
            this.snackBar.open(
                this.translocoService.translate(
                    'dashboard.aboutme-editor.component-forms.profile-form.please-fill-out-all-required-fields',
                ),
                'Close',
                { duration: 3000 },
            );
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
