import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemainingSpaceComponent } from './remaining-space.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [RemainingSpaceComponent],
    imports: [CommonModule, MatProgressBarModule],
    exports: [RemainingSpaceComponent],
})
export class RemainingSpaceModule {}
