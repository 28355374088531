<div class="m-4" *ngIf="remainingSpace$ | async as remainingSpace">
    <mat-progress-bar
        mode="determinate"
        [value]="
            (remainingSpace.usedSpace / remainingSpace.availableSpace) * 100
        "
        class="progress-bar"
    ></mat-progress-bar>
    <p class="text-sm text-gray-500">
        {{ formatSpace(remainingSpace.usedSpace) }} used out of
        {{ formatSpace(remainingSpace.availableSpace) }}
    </p>
</div>
