<form
    class="mt-4 bg-white rounded-lg shadow-md p-4"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "title-section" | transloco }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>

    <!-- Add a checkbox to toggle the use of coordinates -->
    <mat-checkbox
        formControlName="useCoordinates"
        (change)="useCoordinates = !useCoordinates"
        >{{
            "dashboard.aboutme-editor.component-forms.location-form.use-coordinates"
                | transloco
        }}</mat-checkbox
    >

    <!-- Latitude and Longitude fields, initially hidden -->
    <ng-container *ngIf="form.controls['useCoordinates'].value">
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
                "dashboard.aboutme-editor.component-forms.location-form.latitude"
                    | transloco
            }}</mat-label>
            <input matInput formControlName="lat" type="number" required />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>{{
                "dashboard.aboutme-editor.component-forms.location-form.longitude"
                    | transloco
            }}</mat-label>
            <input matInput formControlName="lng" type="number" required />
        </mat-form-field>
    </ng-container>

    <!-- Search Location field, hidden when useCoordinates is checked -->
    <ng-container *ngIf="!form.controls['useCoordinates'].value">
        <mat-form-field appearance="fill" class="w-full">
            <mat-label>
                {{
                    "dashboard.aboutme-editor.component-forms.location-form.search-location"
                        | transloco
                }}</mat-label
            >
            <input
                matInput
                formControlName="searchLocation"
                type="text"
                (keydown.enter)="
                    $event.preventDefault();
                    findLocation(form.controls['searchLocation'].value)
                "
            />
            <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="findLocation(form.controls['searchLocation'].value)"
            >
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </ng-container>

    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.location-form.marker-title"
                | transloco
        }}</mat-label>
        <input matInput formControlName="markerTitle" type="text" required />
    </mat-form-field>

    <div #mapContainer class="w-full aspect-w-1 aspect-h-1 z-0"></div>
    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="form.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
