import { Type } from '@angular/core';
import { ComponentType } from '../aboutme/aboutme.component';
import { AudioFormComponent } from './component-forms/audio-form/audio-form.component';
import { EventsFormComponent } from './component-forms/events-form/events-form.component';
import { FormattedTextFormComponent } from './component-forms/formatted-text-form/formatted-text-form.component';
import { ImageGridFormComponent } from './component-forms/image-grid-form/image-grid-form.component';
import { LocationFormComponent } from './component-forms/location-form/location-form.component';
import { ProfileFormComponent } from './component-forms/profile-form/profile-form.component';
import { ReviewsFormComponent } from './component-forms/reviews-form/reviews-form.component';
import { TechChartFormComponent } from './component-forms/techchart-form/techchart-form.component';
import { TimelineFormComponent } from './component-forms/timeline-form/timeline-form.component';
import { VideoFormComponent } from './component-forms/video-form/video-form.component';
import { TranslocoService } from '@ngneat/transloco';

export interface AboutMeComponent {
    type: ComponentType;
    name: string;
    icon: string;
    description: string;
    formComponent?: Type<any>;
}

export function getAvailableComponents(
    translocoService: TranslocoService,
): AboutMeComponent[] {
    return [
        {
            type: 'audio',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.audio.name',
            ),
            icon: 'audiotrack',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.audio.description',
            ),
            formComponent: AudioFormComponent,
        },
        {
            type: 'events',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.events.name',
            ),
            icon: 'event',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.events.description',
            ),
            formComponent: EventsFormComponent,
        },
        {
            type: 'formattedText',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.formattedText.name',
            ),
            icon: 'text_fields',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.formattedText.description',
            ),
            formComponent: FormattedTextFormComponent,
        },
        {
            type: 'imageGrid',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.imageGrid.name',
            ),
            icon: 'grid_on',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.imageGrid.description',
            ),
            formComponent: ImageGridFormComponent,
        },
        {
            type: 'location',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.location.name',
            ),
            icon: 'place',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.location.description',
            ),
            formComponent: LocationFormComponent,
        },
        {
            type: 'profile',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.profile.name',
            ),
            icon: 'person',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.profile.description',
            ),
            formComponent: ProfileFormComponent,
        },
        {
            type: 'reviews',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.reviews.name',
            ),
            icon: 'star',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.reviews.description',
            ),
            formComponent: ReviewsFormComponent,
        },
        {
            type: 'techChart',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.techChart.name',
            ),
            icon: 'bar_chart',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.techChart.description',
            ),
            formComponent: TechChartFormComponent,
        },
        {
            type: 'timeline',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.timeline.name',
            ),
            icon: 'timeline',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.timeline.description',
            ),
            formComponent: TimelineFormComponent,
        },
        {
            type: 'video',
            name: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.video.name',
            ),
            icon: 'videocam',
            description: translocoService.translate(
                'dashboard.aboutme-editor.component-menu.video.description',
            ),
            formComponent: VideoFormComponent,
        },
    ];
}
