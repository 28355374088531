<form
    class="mt-4 bg-white rounded-lg shadow-md p-4"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.profile-form.name"
                | transloco
        }}</mat-label>
        <input matInput formControlName="name" type="text" required />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.profile-form.job-title"
                | transloco
        }}</mat-label>
        <input matInput formControlName="jobTitle" type="text" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.profile-form.email"
                | transloco
        }}</mat-label>
        <input matInput formControlName="email" type="email" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.profile-form.phone"
                | transloco
        }}</mat-label>
        <input matInput formControlName="phone" type="text" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{
            "dashboard.aboutme-editor.component-forms.profile-form.bio"
                | transloco
        }}</mat-label>
        <textarea matInput formControlName="bio" required></textarea>
    </mat-form-field>
    <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-1">{{
            "dashboard.aboutme-editor.component-forms.profile-form.profile-image"
                | transloco
        }}</label>
        <div class="flex items-center space-x-4">
            <input
                type="file"
                accept="image/*"
                (change)="onFileSelected($event)"
                hidden
                #fileInput
            />
            <button
                type="button"
                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                (click)="$event.preventDefault(); fileInput.click()"
            >
                {{
                    "dashboard.aboutme-editor.component-forms.profile-form.upload-image"
                        | transloco
                }}
            </button>
            <div class="relative">
                <img
                    *ngIf="form.get('imageFileKey')?.value"
                    [src]="imageBaseUrl + form.get('imageFileKey')?.value"
                    alt="Profile Image Preview"
                    class="w-48 h-48 object-cover"
                />
                <button
                    *ngIf="form.get('imageFileKey')?.value"
                    type="button"
                    class="absolute top-0 right-0 text-white rounded-full bg-slate-600 w-8 h-8 p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    (click)="removeImage()"
                >
                    <mat-icon class="text-white">delete</mat-icon>
                </button>
            </div>
        </div>
        <app-remaining-space></app-remaining-space>
    </div>
    <div class="button-container mt-4">
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="form.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
