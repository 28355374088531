<form [formGroup]="techChartForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "title-section" | transloco }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>
    <div formArrayName="skills">
        <div
            *ngFor="let skill of skills.controls; let i = index"
            [formGroupName]="i"
            class="bg-white shadow-md rounded-lg p-4 mb-4"
        >
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.techchart-form.name"
                        | transloco
                }}</mat-label>
                <input matInput formControlName="name" type="text" required />
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.techchart-form.level"
                        | transloco
                }}</mat-label>
                <input
                    matInput
                    formControlName="level"
                    type="number"
                    required
                    min="1"
                    max="10"
                />
            </mat-form-field>
            <button
                mat-icon-button
                (click)="removeSkill(i)"
                class="ml-2 text-grey-500"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="button-container mt-4">
        <button
            class="w-full border border-primary text-primary p-2 mb-2 flex items-center justify-center"
            type="button"
            (click)="addSkill()"
        >
            {{
                "dashboard.aboutme-editor.component-forms.techchart-form.add-skill"
                    | transloco
            }}
        </button>
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="techChartForm.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
