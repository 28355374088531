import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    FormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ComponentFormData } from '../component-form-data';
import { TimelineEvent } from 'app/modules/aboutme/aboutme-timeline/aboutme-timeline.component';
import {
    CdkDragDrop,
    DragDropModule,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import { TranslocoModule } from '@ngneat/transloco';

export interface TimelineData extends ComponentFormData {
    title: string;
    timelineEvents: TimelineEvent[];
}

@Component({
    selector: 'timeline-form',
    templateUrl: './timeline-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        TranslocoModule,
    ],
})
export class TimelineFormComponent {
    timelineForm: FormGroup;

    @Input() data: TimelineData;
    @Output() dataChange = new EventEmitter<TimelineData>();
    @Output() cancel = new EventEmitter<void>();

    constructor(private fb: FormBuilder) {
        this.timelineForm = this.fb.group({
            title: ['', Validators.required],
            timelineEvents: this.fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            this.timelineForm.patchValue({
                title: this.data.title,
            });
            if (this.data.timelineEvents) {
                this.data.timelineEvents.forEach((event, index) => {
                    this.timelineEvents.push(
                        this.fb.group({
                            year: [event.year, Validators.required],
                            title: [event.title, Validators.required],
                            company: [event.company, Validators.required],
                            description: [
                                event.description,
                                Validators.required,
                            ],
                            position: [index],
                        }),
                    );
                });
            }
        }
    }

    get timelineEvents(): FormArray {
        return this.timelineForm.get('timelineEvents') as FormArray;
    }

    addTimelineEvent() {
        const eventGroup = this.fb.group({
            year: ['', Validators.required],
            title: ['', Validators.required],
            company: ['', Validators.required],
            description: ['', Validators.required],
            position: [this.timelineEvents.length],
        });
        this.timelineEvents.push(eventGroup);
    }

    removeTimelineEvent(index: number) {
        this.timelineEvents.removeAt(index);
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        moveItemInArray(
            this.timelineEvents.controls,
            event.previousIndex,
            event.currentIndex,
        );
        this.timelineForm.setControl(
            'timelineEvents',
            this.fb.array(this.timelineEvents.controls),
        );
        this.timelineForm.markAsDirty();
    }

    onSubmit() {
        if (this.timelineForm.valid) {
            this.dataChange.emit(this.timelineForm.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
