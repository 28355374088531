import { Component, OnInit, OnDestroy } from '@angular/core';
import { AboutMeService } from '../../aboutme.service';
import { RemainingSpaceDto } from '../../dto/remainingSpace.dto';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-remaining-space',
    templateUrl: './remaining-space.component.html',
})
export class RemainingSpaceComponent implements OnInit, OnDestroy {
    remainingSpace$: Observable<RemainingSpaceDto>;
    private subscription: Subscription;

    constructor(private aboutMeService: AboutMeService) {}

    ngOnInit(): void {
        this.loadRemainingSpace();
        this.subscription = this.aboutMeService.remainingSpaceUpdated.subscribe(
            () => {
                this.loadRemainingSpace();
            },
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    loadRemainingSpace(): void {
        this.remainingSpace$ = this.aboutMeService.getRemainingSpace();
    }

    formatSpace(bytes: number): string {
        const mb = bytes / (1024 * 1024);
        const gb = bytes / (1024 * 1024 * 1024);
        return gb >= 1 ? `${gb.toFixed(2)} GB` : `${mb.toFixed(2)} MB`;
    }
}
