import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    FormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ComponentFormData } from '../component-form-data';
import { ImageItem } from 'app/modules/aboutme/aboutme-image-grid/aboutme-image-grid.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AboutMeService } from '../../aboutme.service';
import { environment } from 'environments/environment';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RemainingSpaceModule } from '../../components/remaining-space/remaining-space.module';

export interface ImageGridData extends ComponentFormData {
    title: string;
    images: ImageItem[];
}

@Component({
    selector: 'image-grid-form',
    templateUrl: './image-grid-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        MatSnackBarModule,
        TranslocoModule,
        RemainingSpaceModule,
    ],
})
export class ImageGridFormComponent {
    imageGridForm: FormGroup;

    @Input() data: ImageGridData;
    @Output() dataChange = new EventEmitter<ImageGridData>();
    @Output() cancel = new EventEmitter<void>();

    imageBaseUrl = `${environment.apiUrl}/files/`;

    constructor(
        private fb: FormBuilder,
        private aboutMeService: AboutMeService,
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService,
    ) {
        this.imageGridForm = this.fb.group({
            title: ['', Validators.required],
            images: this.fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            this.imageGridForm.patchValue({
                title: this.data.title,
            });
            if (this.data.images) {
                this.data.images.forEach((image, index) => {
                    this.images.push(
                        this.fb.group({
                            caption: [image.caption],
                            fileKey: [image.fileKey, Validators.required],
                            position: [index],
                        }),
                    );
                });
            }
        }
    }

    get images(): FormArray {
        return this.imageGridForm.get('images') as FormArray;
    }

    addImage() {
        const imageGroup = this.fb.group({
            caption: [''],
            fileKey: ['', Validators.required],
            position: [this.images.length],
        });
        this.images.push(imageGroup);
    }

    removeImage(index: number) {
        this.images.removeAt(index);
    }

    onSubmit() {
        if (this.imageGridForm.valid) {
            this.dataChange.emit(this.imageGridForm.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }

    onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    handleFiles(files: FileList) {
        const validImageTypes = [
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/webp',
        ];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validImageTypes.includes(file.type)) {
                this.snackBar.open(
                    this.translocoService.translate(
                        'dashboard.aboutme-editor.component-forms.image-grid.invalid-file-type',
                    ),
                    'Close',
                    {
                        duration: 3000,
                        panelClass: ['error-snackbar'],
                    },
                );
                continue;
            }

            if (file.size > 1048576) {
                // 1MB in bytes
                this.snackBar.open(
                    this.translocoService.translate(
                        'dashboard.aboutme-editor.component-forms.image-grid.file-size-exceeds-1mb',
                    ),
                    'Close',
                    {
                        duration: 3000,
                        panelClass: ['error-snackbar'],
                    },
                );
                continue;
            }

            this.aboutMeService.uploadFile(file).subscribe({
                next: (response) => {
                    const imageGroup = this.fb.group({
                        caption: [''],
                        fileKey: [response.key, Validators.required],
                        position: [this.images.length],
                    });
                    this.images.push(imageGroup);
                    this.aboutMeService.notifyRemainingSpaceUpdate();
                },
                error: (error) => {
                    console.error('Error uploading file:', error);
                },
            });
        }
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files) {
            this.handleFiles(input.files);
        }
    }

    onDrop(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer?.files;
        if (files) {
            this.handleFiles(files);
        }
    }
}
