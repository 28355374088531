import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    FormArray,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ComponentFormData } from '../component-form-data';
import { Skill } from 'app/modules/aboutme/aboutme-techchart/aboutme-techchart.component';
import { TranslocoModule } from '@ngneat/transloco';

export interface TechChartData extends ComponentFormData {
    title: string;
    skills: Skill[];
}

@Component({
    selector: 'techchart-form',
    templateUrl: './techchart-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class TechChartFormComponent {
    techChartForm: FormGroup;

    @Input() data: TechChartData;
    @Output() dataChange = new EventEmitter<TechChartData>();
    @Output() cancel = new EventEmitter<void>();

    constructor(private fb: FormBuilder) {
        this.techChartForm = this.fb.group({
            title: ['', Validators.required],
            skills: this.fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            this.techChartForm.patchValue({
                title: this.data.title,
            });
            if (this.data.skills) {
                this.data.skills.forEach((skill, index) => {
                    this.skills.push(
                        this.fb.group({
                            name: [skill.name, Validators.required],
                            level: [
                                skill.level,
                                [
                                    Validators.required,
                                    Validators.min(1),
                                    Validators.max(10),
                                ],
                            ],
                            position: [index],
                        }),
                    );
                });
            }
        }
    }

    get skills(): FormArray {
        return this.techChartForm.get('skills') as FormArray;
    }

    addSkill() {
        const skillGroup = this.fb.group({
            name: ['', Validators.required],
            level: [
                0,
                [Validators.required, Validators.min(1), Validators.max(10)],
            ],
            position: [this.skills.length],
        });
        this.skills.push(skillGroup);
    }

    removeSkill(index: number) {
        this.skills.removeAt(index);
    }

    onSubmit() {
        if (this.techChartForm.valid) {
            this.dataChange.emit(this.techChartForm.value);
        }
    }

    onCancel(): void {
        this.cancel.emit();
    }
}
