<form [formGroup]="timelineForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "title-section" | transloco }}</mat-label>
        <input matInput formControlName="title" type="text" required />
    </mat-form-field>
    <div
        formArrayName="timelineEvents"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
    >
        <div
            *ngFor="let event of timelineEvents.controls; let i = index"
            [formGroupName]="i"
            class="bg-white shadow-md rounded-lg p-4 mb-4 cursor-move"
            cdkDrag
        >
            <div cdkDragHandle class="drag-handle">
                <mat-icon>drag_handle</mat-icon>
            </div>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.timeline-form.year"
                        | transloco
                }}</mat-label>
                <input matInput formControlName="year" type="text" required />
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.timeline-form.title"
                        | transloco
                }}</mat-label>
                <input matInput formControlName="title" type="text" required />
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.timeline-form.company"
                        | transloco
                }}</mat-label>
                <input
                    matInput
                    formControlName="company"
                    type="text"
                    required
                />
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-full">
                <mat-label>{{
                    "dashboard.aboutme-editor.component-forms.timeline-form.description"
                        | transloco
                }}</mat-label>
                <textarea
                    matInput
                    formControlName="description"
                    required
                ></textarea>
            </mat-form-field>
            <button
                mat-icon-button
                (click)="removeTimelineEvent(i)"
                class="ml-2 text-grey-500"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="button-container mt-4">
        <button
            class="w-full border border-primary text-primary p-2 mb-2 flex items-center justify-center"
            type="button"
            (click)="addTimelineEvent()"
        >
            {{
                "dashboard.aboutme-editor.component-forms.timeline-form.add-event"
                    | transloco
            }}
        </button>
        <button
            class="w-full bg-primary text-white p-2 mb-2 flex items-center justify-center"
            type="submit"
            [disabled]="timelineForm.invalid"
        >
            {{ "save" | transloco }}
        </button>
        <button type="button" class="text-center" (click)="onCancel()">
            {{ "cancel" | transloco }}
        </button>
    </div>
</form>
