<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div
    class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card"
>
    <!-- Header -->
    <div
        class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden"
    >
        <div
            class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card"
        >
            <!-- Top bar -->
            <div
                class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-6"
            >
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center mx-2">
                        <img
                            class="w-50"
                            src="assets/images/logo/logo.svg"
                            alt="Logo image"
                        />
                    </div>
                </ng-container>
                <span
                    *ngIf="isScreenSmall"
                    class="text-2xl font-extrabold w-50"
                >
                    <img
                        class="w-40"
                        src="assets/images/logo/logo.svg"
                        alt="Logo image"
                    />
                </span>
                <!-- Components -->
                <div
                    class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2"
                >
                    <languages></languages>
                    <user></user>
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:px-8">
        <div
            class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default"
        >
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex justify-center w-full z-49 print:hidden">
        <div
            class="flex items-center max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default"
        >
            <span class="font-medium text-secondary"
                >EmailSimple &copy; {{ currentYear }} |
                <a [routerLink]="'/terms'">Terms & Conditions</a> |
                <a [routerLink]="'/cookies'"> Cookies </a> |
                <a [routerLink]="'/privacypolicy'"> Privacy policy </a> |
                <a href="mailto:support@emailsimple.info">
                    {{ "support" | transloco }}
                </a>
            </span>
        </div>
    </div>
</div>
